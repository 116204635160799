import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

import Logo from './Logo';
import TopBar from './TopBar';
import MainMenu from './MainMenu';
import Basket from './basket';
import { PAYMENT_MODE_TEST, PAYMENT_MODE } from '../../utils/payments';

import throttle from 'lodash.throttle';

import s from './index.module.scss';

const getScrollPosition = () => {
  if (typeof window === 'undefined') {
    return 0;
  }
  return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
};

export const useScroll = (timeout = 200) => {
  const [currentScrollTop, setCurrentScrollTop] = useState(getScrollPosition());
  const currentScrollTopRef = React.useRef(currentScrollTop);

  const [currentDirection, setCurrentDirection] = useState('up');
  const currentDirectionRef = React.useRef(currentDirection);

  const [changedAt, setChangedAt] = useState(0);
  const changedAtRef = React.useRef(changedAt);

  useEffect(() => {
    const handleDocumentScroll = () => {
      const scrollTop = getScrollPosition();

      const diff = Math.abs(currentScrollTop - scrollTop);

      // Check for the updated "current direction" only when a page was scrolled
      // more than on 2 px, and if latest change was done more than "timeout*1.2" ms ago.
      // Initially we tried to wrap the listener callback in "throttle" (from lodash) and it would
      // be working in the case we have only once created "event listener". In fact, while
      // a user is scrolling the page, "useEffect" constantly calling and "scroll" listener
      // is constantly recreated.
      if (diff >= 2 && Date.now() - changedAtRef.current > timeout * 1.2) {
        if (scrollTop < currentScrollTopRef.current && currentDirectionRef.current !== 'up') {
          currentDirectionRef.current = 'up';
          setCurrentDirection('up');

          changedAtRef.current = Date.now();
          setChangedAt(changedAtRef.current);
        } else if (
          scrollTop > currentScrollTopRef.current &&
          scrollTop > 34 &&
          currentDirectionRef.current !== 'down'
        ) {
          currentDirectionRef.current = 'down';
          setCurrentDirection('down');

          changedAtRef.current = Date.now();
          setChangedAt(changedAtRef.current);
        }
      }

      currentScrollTopRef.current = scrollTop;
      setCurrentScrollTop(scrollTop);
    };

    const handleDocumentScrollThrottled = throttle(handleDocumentScroll, timeout);
    window.addEventListener('scroll', handleDocumentScrollThrottled);

    return () => {
      window.removeEventListener('scroll', handleDocumentScrollThrottled);
    };
  }, []);

  return {
    scrollTop: currentScrollTopRef.current,
    time: timeout,
    direction: currentDirectionRef.current,
  };
};

const GlobalHeader = ({ siteContentSettings }) => {
  const [isMenuCollapsed, setMenuCollapsed] = useState(true);
  const router = useRouter();
  const { direction } = useScroll();

  // Close mobile menu on any route change.
  useEffect(() => {
    if (!isMenuCollapsed) {
      setMenuCollapsed(true);
    }
  }, [router]);

  const mobileMenuToggle = () => {
    setMenuCollapsed(!isMenuCollapsed);
  };
  return (
    <header
      className={`navbar navbar-sticky ${!siteContentSettings.header.leftText && !siteContentSettings.header.rightText ? 'without-topbar' : ''}`}
    >
      {(siteContentSettings.header.leftText || siteContentSettings.header.rightText) && (
        <TopBar
          className={direction === 'down' ? s['top-bar--collapsed'] : ''}
          headerLeftText={siteContentSettings.header.leftText}
          headerRightText={siteContentSettings.header.rightText}
        />
      )}
      <div className="container header-container">
        {/* Visible only on the mobile */}
        <div className="mobile-menu-wrapper">
          <button
            className={`mobile-menu-toggle${isMenuCollapsed ? '' : ' active'}`}
            onClick={mobileMenuToggle}
          >
            <i className="material-icons menu" />
          </button>
        </div>

        <Logo />
        {/* TODO: Move the region switcher to the footer as per design in the #187099667*/}
        {/*<RegionSwitcher {...siteContentSettings.regionSettings} />*/}

        <MainMenu
          isMenuCollapsed={isMenuCollapsed}
          onMenuClick={mobileMenuToggle}
          menu={siteContentSettings.header.menu}
          regionSwitcherSettings={siteContentSettings.regionSettings}
        />

        <Basket />
      </div>
      {PAYMENT_MODE === PAYMENT_MODE_TEST && (
        <div className={s['test-payment-mode-warning']}>
          <a href="https://cw2.readme.io/docs/payments" target="_blank" rel="noopener noreferrer">
            TEST MODE
          </a>
        </div>
      )}
    </header>
  );
};

GlobalHeader.propTypes = {
  siteContentSettings: PropTypes.object.isRequired,
};

export default GlobalHeader;
