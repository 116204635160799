import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import fetch from '../../utils/fetch';
import rollbar from '../../utils/rollbar';
import { DEFAULT_COUNTRY_CODE } from '../../utils/constants';
import Button from '../../components/01_atoms/Button';
import Popup from '../Popup';

import styles from './index.module.scss';
import FormattedText from '../../components/01_atoms/FormattedText';

const RegionPopup = ({ title, description, regions, isRegionPopupEnabled }) => {
  const [isVisible, setVisible] = useState(false);
  const [suggestedRegion, setSuggestedRegion] = useState(false);

  const onHide = () => {
    localStorage.setItem('giftsRegionPopupClosed', 'closed');
    setVisible(false);
  };

  useEffect(() => {
    const isFromAnotherRegion = regions.find(
      (region) =>
        // Success if a given URL starts from region base URL.
        document.referrer.search(region.link.nextLink.url) === 0,
    );

    // Do nothing if RegionPopup was already closed once.
    if (!localStorage.getItem('giftsRegionPopupClosed')) {
      // If a user came from another region then they most likely know how to
      // switch site regions. Disable the popup for them.
      if (isFromAnotherRegion) {
        onHide();
      } else {
        const loadCurrentRegion = async () => {
          try {
            // Resolving name and email from CRM.
            const response = await fetch('/falcon/multiregion');

            if (response.body && response.body.user_suggested_region) {
              const suggestedRegionCode = response.body.user_suggested_region;
              const suggestedRegionSettings = regions.find(
                (region) => region.code === suggestedRegionCode,
              );
              if (DEFAULT_COUNTRY_CODE !== suggestedRegionCode && suggestedRegionSettings) {
                setVisible(true);
                setSuggestedRegion(suggestedRegionSettings);
              }
            }
          } catch {
            if (rollbar) {
              rollbar.error('Error during request to /falcon/multirigion');
            }
          }
        };
        loadCurrentRegion().catch(() => {
          rollbar.error('Error during promise request to /falcon/multirigion ');
        });
      }
    }
  }, []);

  if (!isRegionPopupEnabled) {
    return null;
  }

  if (!title || !description) {
    return null;
  }

  if (!isVisible) {
    return null;
  }

  const defaultRegion = regions.find((region) => region.code === DEFAULT_COUNTRY_CODE);
  if (!suggestedRegion || !defaultRegion) {
    return null;
  }

  return (
    <Popup onRequestClose={onHide} isOpen={isVisible}>
      <div className={styles['region-popup']}>
        <h3 className={styles['region-popup__title']}>{title}</h3>
        <FormattedText text={description} className={styles['region-popup__description']} />
        <div className={styles['region-popup__buttons']}>
          <Button isOutlined className="btn-block btn-secondary btn btn-default" onClick={onHide}>
            Continue on {defaultRegion.title}
          </Button>
          <Button
            tag="a"
            className="btn-block btn btn-primary"
            href={suggestedRegion.link.nextLink.url}
          >
            Go to {suggestedRegion.label}
          </Button>
        </div>
      </div>
    </Popup>
  );
};

RegionPopup.propTypes = {
  isRegionPopupEnabled: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  regions: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      link: PropTypes.object,
      image: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
      }),
    }),
  ).isRequired,
};

export default RegionPopup;
