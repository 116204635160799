import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import FormattedText from '../../components/01_atoms/FormattedText';
import Link from '../Link';
import { OriginalUrlContext } from '../../utils/context';
import RegionSwitcher from '../GlobalHeader/RegionSwitcher';
import Logo from '../../public/static/images/footer-logo.svg';
import { DEFAULT_COUNTRY_CODE } from '../../utils/constants';

import styles from './index.module.scss';

const GlobalFooter = ({ footerSettings, regionSwitcherSettings }) => {
  const originalUrl = useContext(OriginalUrlContext);

  return (
    <footer className={styles['footer']}>
      <div className="container">
        <div className={styles['footer__top']}>
          <div className={styles['footer__top-logo']}>
            <Logo aria-label="Concern worldwide" role="img" />
          </div>
          <div className={styles['footer__top-description']}>
            <FormattedText text={footerSettings.topText} />
          </div>
          <div className={styles['footer__top-menu']}>
            <ul className="menu">
              {footerSettings.menu.map((item) => (
                <li key={item.uuid}>
                  <Link href={item.url}>
                    <a className={originalUrl.split('?')[0] === item.url ? 'active' : null}>
                      {item.title}
                    </a>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={styles['footer__cards']}>
          <img src="/static/Gifts/images/visa.svg" alt="Visa" title="Visa" />
          <img src="/static/Gifts/images/mc.svg" alt="Mastercard" title="Mastercard" />
          <img src="/static/Gifts/images/applepay.svg" alt="Apple Pay" title="Apple Pay" />
          <img src="/static/Gifts/images/gpay.svg" alt="Google Pay" title="Google Pay" />
          <img src="/static/Gifts/images/paypal.svg" alt="PayPal" title="PayPal" />
          <img src="/static/Gifts/images/revolut.svg" alt="Revolut" title="Revolut" />
        </div>
        <div className={styles['footer__bottom']}>
          {footerSettings.leftText && <FormattedText text={footerSettings.leftText} />}
          <div className="mobile-menu-region-switcher-wrapper">
            <RegionSwitcher {...regionSwitcherSettings} className="mobile-menu-region-switcher" />
          </div>
        </div>
      </div>
    </footer>
  );
};

GlobalFooter.propTypes = {
  footerSettings: PropTypes.object.isRequired,
  regionSwitcherSettings: PropTypes.object.isRequired,
};

export default GlobalFooter;
