import { useSelector } from 'react-redux';
import { formattedPrice } from '../../utils/Gifts/products';
import { getTotal } from '../../utils/Gifts/basket';

const BasketTotal = () => {
  const basketTotal = useSelector(({ basket }) => getTotal(basket.products));
  return formattedPrice(basketTotal);
};

export default BasketTotal;
