import React from 'react';
import PropTypes from 'prop-types';
import { getOfferings } from '../../utils/Gifts/basket';
import { useSelector } from 'react-redux';
import FormattedText from '../../components/01_atoms/FormattedText';

import styles from './index.module.scss';

const FreeProductsOfferings = ({ variant }) => {
  const freeProducts = useSelector((state) => state.productsStorage.freeGifts);
  const basketProducts = useSelector((state) => state.basket.products);
  const activeOfferings = getOfferings(basketProducts, Object.values(freeProducts));
  return (
    <div>
      {activeOfferings.map((offer) => (
        <FormattedText
          className={`${styles['free-product-offer']} ${styles[`free-product-offer--${variant}`]}`}
          key={offer.id}
          text={offer.message}
        />
      ))}
    </div>
  );
};

FreeProductsOfferings.propTypes = {
  variant: PropTypes.oneOf(['basket-widget', 'basket-page']).isRequired,
};

export default FreeProductsOfferings;
