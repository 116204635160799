import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import { withRouter } from 'next/router';
import MobileIcon from '../../../public/static/icons/mobile.svg';
import TabletIcon from '../../../public/static/icons/tablet.svg';
import DesktopIcon from '../../../public/static/icons/desktop.svg';
import CloseIcon from '../../../public/static/icons/close-green.svg';
import AlertComponent from '../../01_atoms/Alert';
import fetch from '../../../utils/fetch.js';
import { BACKEND_URL } from '../../../utils/constants';
import styles from './index.module.scss';
import Button from '../../01_atoms/Button';
import { toast } from 'react-toastify';

const debug = require('debug')('cw:PagePreview');

class PagePreview extends React.Component {
  constructor(props) {
    super(props);

    const { router } = props;
    const query = router.query || {};
    const previewMode = query.previewMode || false;
    const previewToken = query.previewToken || '';
    const currentURL = router.asPath.split('?')[0];
    // During SSR, router.asPath contains system path, not the alias,
    // and it is replacing by alias on the client,
    // which causes react hydration error when iframe is rendered.
    // Introducing a flag to distinguish these states.
    const isReady = false;

    this.state = {
      viewport: 'desktop',
      hasEditPermission: false,
      isSaving: false,
      currentURL,
      previewToken,
      previewMode,
      isReady,
    };

    this.switchViewport = this.switchViewport.bind(this);
    this.exitPreviewMode = this.exitPreviewMode.bind(this);
    this.publishNode = this.publishNode.bind(this);
  }

  async componentDidMount() {
    const { previewMode, previewToken } = this.state;

    if (!previewMode) {
      return;
    }

    // Check if the current user has permission to update the node
    // in preview mode.
    fetch(`/content_preview/has_update_permission/${previewToken}`, {
      query: { _format: 'json' },
      credentials: 'include',
    }).then(({ body }) => {
      this.setState({
        hasEditPermission: body.update_access || false,
      });
    });

    // Now the iframe is ready to be rendered with the proper URL value.
    this.setState({ isReady: true });
  }

  switchViewport(viewport) {
    this.setState({ viewport });
  }

  exitPreviewMode() {
    const { currentURL } = this.state;
    // Refresh the page without URL query string.
    window.location = currentURL;
  }

  async publishNode() {
    const { previewToken } = this.state;
    this.setState({ isSaving: true });

    // Get CSRF Token.
    const response = await fetch('/session/token', {
      query: { _format: 'json' },
      credentials: 'include',
    });
    const token = response.body;

    fetch(`/content_preview/publish/${previewToken}`, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      query: {
        _format: 'json',
      },
    })
      .then(({ statusCode }) => {
        if (statusCode === 200) {
          toast.success('Successfully published. Reloading the page.');
          this.exitPreviewMode();
        } else {
          toast.error('Sorry, could not update the page.');
        }
      })
      .catch(() => {
        toast.error('Sorry, could not update the page.');
      })
      .finally(() => {
        this.setState({ isSaving: false });
      });
  }

  render() {
    const { children } = this.props;
    const {
      viewport,
      hasEditPermission,
      isSaving,
      previewMode,
      previewToken,
      currentURL,
      isReady,
    } = this.state;

    // When there is no valid preview params in the URL - return
    // the unmodified children object.
    if (!previewMode || !previewToken) {
      return children;
    }

    // Decode token from the query and make sure it contains required
    // token params in it.
    const decodedPreviewToken = Buffer.from(previewToken, 'base64').toString();
    const [nid, vid, uid, token, language] = decodedPreviewToken.split(':');

    // If preview token does not contain required arguments and can't be
    // parsed correctly - then we can't enter a preview mode successfully.
    if (!(nid && vid && uid && token)) {
      debug('Preview token is invalid: %s. Showing usual page instead.', previewToken);
      return children;
    }

    const editButtonLink =
      language === 'en'
        ? `${BACKEND_URL}/node/${nid}/revisions/${vid}/edit`
        : `${BACKEND_URL}/${language}/node/${nid}/revisions/${vid}/edit`;

    return (
      <div className={`page-preview ${styles['page-preview']}`}>
        <Head>
          <title>Preview</title>
          <meta name="robots" content="noindex, nofollow" />
        </Head>

        <AlertComponent />

        <div className="preview-banner">
          <div className="message">You are in preview mode</div>

          <div className="viewport-buttons">
            {['desktop', 'tablet', 'mobile'].map((viewportName) => (
              <span
                key={viewportName}
                className={`viewport-button ${viewportName} ${
                  viewport === viewportName ? 'active' : ''
                }`}
                onClick={() => this.switchViewport(viewportName)}
                onKeyPress={() => this.switchViewport(viewportName)}
                title={viewportName.charAt(0).toUpperCase() + viewportName.slice(1)}
              >
                {viewportName === 'desktop' && <DesktopIcon />}
                {viewportName === 'tablet' && <TabletIcon />}
                {viewportName === 'mobile' && <MobileIcon />}
              </span>
            ))}
          </div>

          <div className="actionbar">
            {hasEditPermission && (
              <>
                <Button tag="a" size="small" type="secondary" href={editButtonLink}>
                  Edit
                </Button>
                <Button
                  size="small"
                  type="secondary"
                  onClick={this.publishNode}
                  isLoading={isSaving}
                  isDisabled={isSaving}
                >
                  Publish
                </Button>
              </>
            )}

            <CloseIcon className="button-close" onClick={this.exitPreviewMode} />
          </div>
        </div>

        {isReady && (
          <iframe
            title="Page being preview"
            className={`preview-iframe viewport-${viewport}`}
            src={`${currentURL}?previewToken=${previewToken}`}
          />
        )}
      </div>
    );
  }
}

PagePreview.propTypes = {
  router: PropTypes.shape({
    query: PropTypes.shape(),
    asPath: PropTypes.string,
  }).isRequired,
};

export default withRouter(PagePreview);
