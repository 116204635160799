import React from 'react';
import { useSelector } from 'react-redux';
import Link from '../../Link';
import { getCountProducts } from '../../../utils/Gifts/basket';
import BasketWidgetDropdown from './BasketWidgetDropdown';
import ShoppingCartIcon from '../../../public/static/Gifts/images/shopping-cart.svg';

import styles from './index.module.scss';

const BasketIconWithWidget = () => {
  const count = useSelector((state) => getCountProducts(state.basket.products));
  return (
    <div className={`cart-btn ${styles['basket-icon-with-widget']}`}>
      <Link href="/basket">
        <a data-component="header-basket-link">
          <ShoppingCartIcon
            title="BASKET"
            className={styles['basket-icon-with-widget__shopping-cart-icon']}
          />
          {!!count && <span className="count">{count}</span>}
        </a>
      </Link>
      <BasketWidgetDropdown />
    </div>
  );
};

export default BasketIconWithWidget;
