import React from 'react';
import PropTypes from 'prop-types';
import FormattedText from '../../components/01_atoms/FormattedText';

import styles from './index.module.scss';

const TopBar = ({ headerLeftText, headerRightText, className = '' }) => {
  return (
    <div className={`${styles['top-bar']} ${className}`}>
      <div className="container header-container">
        <div className={styles['top-bar__container']}>
          <FormattedText className={styles['top-bar__text-left']} text={headerLeftText} />
          <FormattedText className={styles['top-bar__text-right']} text={headerRightText} />
        </div>
      </div>
    </div>
  );
};

TopBar.propTypes = {
  headerLeftText: PropTypes.string.isRequired,
  headerRightText: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default TopBar;
