import React from 'react';
import BasketIconWithWidget from './BasketIconWithWidget';
import BasketBanner from './BasketBanner';

const Basket = () => (
  <div className="toolbar">
    <div className="inner">
      <BasketBanner />
      <BasketIconWithWidget />
    </div>
  </div>
);

export default Basket;
