import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DEFAULT_COUNTRY_CODE } from '../../utils/constants';

const RegionSwitcher = ({ switcher, regions, className = '' }) => {
  const [isToggled, setToggled] = useState(false);

  if (!switcher || !regions) {
    return null;
  }
  if (regions.length < 2) {
    return null;
  }
  let defaultRegion = regions.filter((region) => region.code === DEFAULT_COUNTRY_CODE);

  if (!defaultRegion.length) {
    return null;
  }
  defaultRegion = defaultRegion[0];

  const dropdownRegions = regions
    // Remove main region from dropdown.
    .filter((region) => region.code !== DEFAULT_COUNTRY_CODE)
    .map((region) => (
      <li key={region.code}>
        <a href={region.link.nextLink.url}>
          <img src={region.image.url} alt={region.image.alt} />
          {region.code}
        </a>
      </li>
    ));

  return (
    <div
      className={`${className} lang-switcher${isToggled ? ' open' : ''}`}
      onClick={() => setToggled(!isToggled)}
      onKeyDown={(e) => (e.key === 'Enter' || e.key === 'Space') && setToggled(!isToggled)}
    >
      <span>Change country</span>
      <div className="lang-toggle" data-analytics={'region-switcher'}>
        <img src={defaultRegion.image.url} alt={defaultRegion.image.alt}/>
        <i className="material-icons arrow_drop_down"/>
        <ul className="lang-dropdown">{dropdownRegions}</ul>
      </div>
    </div>
  );
};

RegionSwitcher.propTypes = {
  switcher: PropTypes.bool.isRequired,
  regions: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      link: PropTypes.object,
      image: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
      }),
    }),
  ).isRequired,
  className: PropTypes.string,
};

export default RegionSwitcher;
