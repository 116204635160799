/* eslint-disable react/prop-types */
import React from 'react';
import GlobalHeader from '../componentsGifts/GlobalHeader';
import GlobalFooter from '../componentsGifts/GlobalFooter';
import {
  SiteSettingsContext,
  CategoriesContext,
  PagesContext,
  OriginalUrlContext,
} from '../utils/context';
import HtmlHead from '../components/01_atoms/HtmlHead';
import config from 'next/config';
import Head from 'next/head';
import Alert from '../components/01_atoms/Alert';
import RegionPopup from '../componentsGifts/RegionPopup';
import { useStore } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import ClearBasketPopup from '../componentsGifts/ClearBasketPopup';
import ErrorNotFound from '../componentsGifts/ErrorNotFound';
import PagePreview from '../components/02_molecules/PagePreview';

const {
  publicRuntimeConfig: { GIFTS_GTM_QUERY, GIFTS_GTM_ID, GIFTS_COOKIEBOT_ID },
} = config();

const GiftsLayout = ({
  Component,
  siteContentSettings,
  giftsData,
  GTMDevMode,
  originalUrl,
  statusCode,
  isPreviewMode,
  ...props
}) => {
  const store = useStore();

  const main = (
    <PersistGate loading={null} persistor={store.__persistor}>
      {/* We need to use function inside to make SSR working as expected. */}
      {() => (
        <SiteSettingsContext.Provider value={siteContentSettings}>
          <CategoriesContext.Provider value={giftsData.categories}>
            <PagesContext.Provider value={giftsData.pages}>
              <OriginalUrlContext.Provider value={originalUrl}>
                <Alert />
                <RegionPopup {...siteContentSettings.regionSettings} />
                <ClearBasketPopup />
                <main>
                  <HtmlHead
                    GTMDevMode={GTMDevMode}
                    GTMId={GIFTS_GTM_ID}
                    GTMQuery={GIFTS_GTM_QUERY}
                    // TODO: add Rollbar token if we need it for Gifts.
                    // TODO: add Convert Enabled if we need it for Gifts.
                    pageTitle=""
                    cookiebotId={GIFTS_COOKIEBOT_ID}
                  />
                  <Head>
                    <style
                      type="text/css"
                      media="all"
                      dangerouslySetInnerHTML={{
                        __html: `@font-face{font-family:'Material Icons';font-style:normal;font-weight:400;src:url(/static/fonts/MaterialIcons-Regular.eot);src:local('Material Icons'),local('MaterialIcons-Regular'),url(/static/fonts/MaterialIcons-Regular.woff2) format('woff2'),url(/static/fonts/MaterialIcons-Regular.woff) format('woff'),url(/static/fonts/MaterialIcons-Regular.ttf) format('truetype')}`,
                      }}
                    />
                  </Head>

                  <GlobalHeader siteContentSettings={siteContentSettings} />
                  <div className="page-content">
                    {statusCode === 200 ? <Component {...props} /> : <ErrorNotFound />}
                  </div>
                  <GlobalFooter
                    footerSettings={siteContentSettings.footer}
                    regionSwitcherSettings={siteContentSettings.regionSettings}
                  />
                </main>
              </OriginalUrlContext.Provider>
            </PagesContext.Provider>
          </CategoriesContext.Provider>
        </SiteSettingsContext.Provider>
      )}
    </PersistGate>
  );

  if (isPreviewMode) {
    return <PagePreview>{main}</PagePreview>;
  }

  return main;
};

export default GiftsLayout;
