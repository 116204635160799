import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../../Link';
import ProductPrice from '../../../ProductPrice';
import DefaultPicture from '../../../../components/01_atoms/DefaultPicture';
import RemoveProductButton from '../../../RemoveProductButton';

const SantaLetterItem = ({ item, cardIndex }) => (
  <div className={`item`}>
    <Link href={item.product.url.url}>
      <a className="item-thumb">
        {item.product?.image && <DefaultPicture {...item.product.image} />}
      </a>
    </Link>

    <div className="item-details">
      <h3 className="item-title">
        <Link href={item.product.url.url}>
          <a>{item.product.title}</a>
        </Link>{' '}
        for {item.name}
      </h3>
      <div className="item-price">
        <ProductPrice product={item.product} />
      </div>
      <RemoveProductButton productId={item.product.id} indexes={[cardIndex]} />
    </div>
  </div>
);

SantaLetterItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    product: PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
      title: PropTypes.string,
      categoryIds: PropTypes.arrayOf(PropTypes.number),
      image: PropTypes.object,
      url: PropTypes.object,
      shortDescription: PropTypes.string,
      relatedGiftsFilled: PropTypes.arrayOf(PropTypes.number),
      actionImage: PropTypes.object,
      actionDescription: PropTypes.string,
      annotation: PropTypes.string,
      giftsInBundle: PropTypes.arrayOf(PropTypes.number),
    }),
  }).isRequired,
  cardIndex: PropTypes.number.isRequired,
  handleDeleteClick: PropTypes.func,
  withLinks: PropTypes.bool,
};

export default SantaLetterItem;
