import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Link from '../Link';
import { OriginalUrlContext } from '../../utils/context';
import RegionSwitcher from './RegionSwitcher';

const MainMenu = ({ isMenuCollapsed, menu, regionSwitcherSettings }) => {
  const originalUrl = useContext(OriginalUrlContext);

  return (
    <nav
      className={`main-navigation ${isMenuCollapsed ? '' : 'open'}`}
      data-analytics={'main-navigation'}
    >
      <ul className="menu">
        {menu.map((item) => (
          <li key={item.uuid}>
            <Link href={item.url}>
              <a
                className={cn({
                  active: originalUrl.split('?')[0] === item.url,
                  [item.style]: item.style,
                })}
              >
                {item.title}
              </a>
            </Link>
          </li>
        ))}
      </ul>
      <div className="mobile-menu-region-switcher-wrapper">
        <RegionSwitcher {...regionSwitcherSettings} className="mobile-menu-region-switcher" />
      </div>
    </nav>
  );
};

MainMenu.propTypes = {
  isMenuCollapsed: PropTypes.bool.isRequired,
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string,
      style: PropTypes.oneOf(['default', 'christmas']),
    }),
  ).isRequired,
  regionSwitcherSettings: PropTypes.object.isRequired,
};

export default MainMenu;
