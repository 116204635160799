import React from 'react';
import Button from '../../components/01_atoms/Button';
import Popup from '../Popup';

import FormattedText from '../../components/01_atoms/FormattedText';
import { useDispatch, useSelector } from 'react-redux';
import { basketClearPopupHide } from '../../store/Slices/gifts/basketClearPopupSlice';

import {
  AddCustomPriceProduct,
  AddProduct,
  basketReset,
} from '../../store/Slices/gifts/basketSlice';

import styles from './index.module.scss';

const ClearBasketPopup = () => {
  const dispatch = useDispatch();
  const isVisible = useSelector((state) => state.basketClearPopup.isVisible);
  const context = useSelector((state) => state.basketClearPopup.context);

  const onProceed = () => {
    dispatch(basketReset());

    if (context.product) {
      dispatch(AddCustomPriceProduct(context));
    } else {
      dispatch(AddProduct(context));
    }

    onHide();
  };

  const onHide = () => {
    dispatch(basketClearPopupHide());
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Popup onRequestClose={onHide} isOpen={isVisible}>
      <div className={styles['clear-basket-popup']}>
        <h3 className={styles['clear-basket-popup__title']}>Confirm to clear basket</h3>
        <FormattedText
          text={
            'Your basket can only contain one type of gifts. If you proceed with adding the gift of your choice then existing gifts will be removed.'
          }
          className={styles['clear-basket-popup__description']}
        />
        <div className={styles['clear-basket-popup__buttons']}>
          <Button isOutlined={false} type="primary" onClick={onProceed} data-component="accept-switch-basket-type">
            Proceed
          </Button>
          <Button type="primary" isOutlined={true} onClick={onHide} data-component="cancel-switch-basket-type">
            Cancel
          </Button>
        </div>
      </div>
    </Popup>
  );
};

export default ClearBasketPopup;
